import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Report.css';

const Report = () => {
  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReportData = async () => {
      try {
        console.log('Fetching report data...');
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/licenses/report`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        console.log('Report data received:', response.data);
        setReportData(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching report data:', err.response?.data || err.message);
        setError(`Failed to fetch report data: ${err.response?.data?.message || err.message}`);
        setLoading(false);
      }
    };

    fetchReportData();
  }, []);

  const calculateDaysUntilExpiration = (expDate) => {
    const today = new Date();
    const expirationDate = new Date(expDate);
    const timeDiff = expirationDate.getTime() - today.getTime();
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return daysDiff;
  };

  const formatTimeUntilExpiration = (expDate) => {
    const daysUntilExpiration = calculateDaysUntilExpiration(expDate);
    if (daysUntilExpiration < 0) {
      return "Expired";
    } else if (daysUntilExpiration === 0) {
      return "Expires today";
    } else {
      return `${daysUntilExpiration} day${daysUntilExpiration !== 1 ? 's' : ''}`;
    }
  };

  if (loading) return <div className="report-loading">Loading report...</div>;
  if (error) return <div className="report-error">{error}</div>;

  // Sort licenses by expiration date (soonest first)
  reportData.licenseDetails.sort((a, b) => new Date(a.ExpDate) - new Date(b.ExpDate));

  return (
    <div className="report-container">
      <h2>License Usage Report</h2>
      <div className="report-summary">
        <p>Total Licenses Issued: {reportData.totalLicenses}</p>
        <p>Total Companies: {reportData.totalCompanies}</p>
        <p>Total Agents in Use: {reportData.totalAgentsInUse}</p>
      </div>
      <table className="report-table">
        <thead>
          <tr>
            <th>Company</th>
            <th>License ID</th>
            <th>Agents Used</th>
            <th>Max Agents</th>
            <th>Time Until Expiration</th>
          </tr>
        </thead>
        <tbody>
          {reportData.licenseDetails.map((license) => {
            const timeUntilExpiration = formatTimeUntilExpiration(license.ExpDate);
            const isExpired = timeUntilExpiration === "Expired";
            return (
              <tr key={license._id} className={isExpired ? 'expired' : ''}>
                <td>{license.companyName}</td>
                <td>{license._id}</td>
                <td>{license.Agents}</td>
                <td>{license.MaxAgents}</td>
                <td data-expiration={timeUntilExpiration}>{timeUntilExpiration}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Report;