import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './LicenseList.css';

const LicenseList = () => {
  const [licenses, setLicenses] = useState([]);
  const [message, setMessage] = useState('');
  const [editingLicense, setEditingLicense] = useState(null);
  const [editingNote, setEditingNote] = useState(null);
  const [formData, setFormData] = useState({
    ExpDate: '',
    MaxAgents: '',
    MaxUsers: '',
    Subscription: '',
    LicenseType: '',
    Agents: '',
    contactEmail: '',
    companyName: '',
    LicenseKey: '',
  });
  const [noteData, setNoteData] = useState('');

  useEffect(() => {
    const fetchLicenses = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/licenses`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setLicenses(response.data.map(license => ({ ...license, isBlocked: false })));
      } catch (error) {
        console.error('Error fetching licenses:', error);
        setMessage('Error fetching licenses');
      }
    };

    fetchLicenses();
  }, []);

  const PenIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
    </svg>
  );


  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${process.env.REACT_APP_BACKEND}/licenses/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setLicenses(licenses.filter(license => license._id !== id));
      setMessage('License deleted successfully');
    } catch (error) {
      console.error('Error deleting license:', error);
      setMessage('Error deleting license');
    }
  };

  const handleEdit = (license) => {
    setEditingLicense(license);
    setFormData({
      ExpDate: license.ExpDate.split('T')[0],
      MaxAgents: license.MaxAgents,
      MaxUsers: license.MaxUsers,
      Subscription: license.Subscription,
      LicenseType: license.LicenseType
    });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const response = await axios.put(`${process.env.REACT_APP_BACKEND}/licenses/${editingLicense._id}`, formData, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setLicenses(licenses.map(license => (license._id === editingLicense._id ? { ...response.data, isBlocked: license.isBlocked } : license)));
      setMessage('License updated successfully');
      setEditingLicense(null);
    } catch (error) {
      console.error('Error updating license:', error);
      setMessage('Error updating license');
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleBlock = (id) => {
    setLicenses(licenses.map(license =>
      license._id === id ? { ...license, isBlocked: !license.isBlocked } : license
    ));
  };

  const isExpired = (date) => {
    return new Date(date) < new Date();
  };

  const formatExpirationDate = (date) => {
    if (isExpired(date)) {
      return "Expired";
    }
    return new Date(date).toLocaleDateString();
  };

  // New functions for handling notes
  const handleAddNote = (license) => {
    setEditingNote(license);
    setNoteData(license.notes || '');
  };

  const handleNoteChange = (e) => {
    setNoteData(e.target.value);
  };

  const handleNoteSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const response = await axios.put(`${process.env.REACT_APP_BACKEND}/licenses/${editingNote._id}`, { notes: noteData }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setLicenses(licenses.map(license => (license._id === editingNote._id ? { ...license, notes: response.data.notes } : license)));
      setMessage('Note updated successfully');
      setEditingNote(null);
    } catch (error) {
      console.error('Error updating note:', error);
      setMessage('Error updating note');
    }
  };

  return (
    <div className="license-list">
      <div className="header">
        <h2>Licenses</h2>
      </div>
      {message && <p className="message">{message}</p>}
      {editingLicense ? (
        <div className="edit-form">
          <h3>Edit License</h3>
          <form onSubmit={handleUpdate}>
            <label>Expiration Date:</label>
            <input type="date" name="ExpDate" value={formData.ExpDate} onChange={handleChange} required />
            <label>Max Agents:</label>
            <input type="number" name="MaxAgents" value={formData.MaxAgents} onChange={handleChange} placeholder="Max Agents" required />
            <label>MaxUsers:</label>
            <input type="number" name="MaxUsers" value={formData.MaxUsers} onChange={handleChange} placeholder="Max Users" required />
            <label>Renewal on Expiration Date:</label>
            <select value={formData.Subscription} onChange={handleChange}>
              <option value={true}>True</option>
              <option value={false}>False</option>
            </select>
            <label>License Type:</label>
            <select value={formData.LicenseType} onChange={handleChange}>
              <option value='Free'>Free</option>
              <option value='Basic'>Basic</option>
              <option value='Enterprise'>Enterprise</option>
            </select>
            <div className="form-buttons">
              <button type="submit" className="update-btn">Update License</button>
              <button type="button" onClick={() => setEditingLicense(null)} className="cancel-btn">Cancel</button>
            </div>
          </form>
        </div>
      ) : editingNote ? (
        <div className="edit-form">
          <h3>Edit Note</h3>
          <form onSubmit={handleNoteSubmit}>
            <textarea value={noteData} onChange={handleNoteChange} placeholder="Enter note" required />
            <div className="form-buttons">
              <button type="submit" className="update-btn">Update Note</button>
              <button type="button" onClick={() => setEditingNote(null)} className="cancel-btn">Cancel</button>
            </div>
          </form>
        </div>
      ) : (
        <table className="license-table">
          <thead>
            <tr>
              <th>License ID</th>
              <th>License Type</th>
              <th>Company</th>
              <th>Description</th>
              <th>Expiration Date</th>
              <th>Subscribed</th>
              <th>Max Agents</th>
              <th>Used Agents</th>
              <th>Email</th>
              <th>License Key</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {licenses.map((license) => (
              <tr key={license._id} className={license.isBlocked ? 'blocked' : ''}>
                <td>{license._id}</td>
                <td>{license.LicenseType}</td>
                <td>{license.companyName}</td>
                <td className="description-cell">
                  <div className="note-container">
                    <p>{license.notes || 'No note'}</p>
                    <button onClick={() => handleAddNote(license)} className="add-note-btn" title="Add/Edit Note">
                      <PenIcon />
                    </button>
                  </div>
                </td>
                <td className={isExpired(license.ExpDate) ? 'expired-date' : ''}>
                  {formatExpirationDate(license.ExpDate)}
                </td>
                <td>{license.Subscription ? "True" : "False"}</td>
                <td>{license.MaxAgents}</td>
                <td>{license.Agents || 0}</td>
                <td>{license.contactEmail}</td>
                <td>{license.LicenseKey}</td>
                <td>
                  <button onClick={() => handleEdit(license)} className="edit-btn">Edit</button>
                  <button onClick={() => handleDelete(license._id)} className="delete-btn">Delete</button>
                  <button onClick={() => handleBlock(license._id)} className={`block-btn ${license.isBlocked ? 'unblock' : ''}`}>
                    {license.isBlocked ? 'Unblock' : 'Block'}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default LicenseList;