import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import LicenseForm from './components/LicenseForm';
import LicenseList from './components/LicenseList';
import Login from './components/Login';
import UserList from './components/UserList';
import MyAccount from './components/MyAccount';
import NavigationBar from './components/NavigationBar';
import Report from './components/Report';
import VMStatus from './components/VMStatus';
import LogViewer from './components/logViewer';
import './App.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
      setUserRole(localStorage.getItem('userRole'));
    }
  }, []);

  const ProtectedRoute = ({ children, allowedRoles = ['user', 'admin'] }) => {
    if (!isAuthenticated) {
      return <Navigate to="/login" replace />;
    }
    if (!allowedRoles.includes(userRole)) {
      return <Navigate to="/" replace />;
    }
    return children;
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userRole');
    setIsAuthenticated(false);
    setUserRole(null);
    navigate('/login');
  };

  const showNavBar = isAuthenticated && location.pathname !== '/login';

  return (
    <div className={`app ${showNavBar ? 'with-navbar' : ''}`}>
      {showNavBar && (
        <NavigationBar userRole={userRole} onLogout={handleLogout} />
      )}
      {location.pathname === '/login' ? (
        <Login setIsAuthenticated={setIsAuthenticated} setUserRole={setUserRole} />
      ) : (
        <div className={`main-content ${showNavBar ? 'with-navbar' : ''}`}>
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <LicenseList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/create"
              element={
                <ProtectedRoute>
                  <LicenseForm />
                </ProtectedRoute>
              }
            />
            <Route
              path="/users"
              element={
                <ProtectedRoute allowedRoles={['admin']}>
                  <UserList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/my-account"
              element={
                <ProtectedRoute>
                  <MyAccount />
                </ProtectedRoute>
              }
            />
            <Route
              path="/report"
              element={
                <ProtectedRoute allowedRoles={['admin']}>
                  <Report />
                </ProtectedRoute>
              }
            />
            <Route
              path="/vm-status"
              element={
                <ProtectedRoute>
                  <VMStatus />
                </ProtectedRoute>
              }
            />
            <Route path="/log-view"
              element={<ProtectedRoute><LogViewer /></ProtectedRoute>} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </div>
      )}
    </div>
  );
}

export default App;