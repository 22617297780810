import React, { useState } from 'react';
import axios from 'axios';
import './LicenseForm.css';

const LicenseForm = () => {
  const [ExpDate, setExpDate] = useState('');
  const [MaxAgents, setMaxAgents] = useState('');
  const [MaxUsers, setMaxUsers] = useState('');
  const [Subscription, setSubscription] = useState(true); // Default value
  const [LicenseType, setLicenseType] = useState('Free'); // Default value
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      console.log('Submitting form with:', { ExpDate, MaxAgents, MaxUsers });
      const response = await axios.post(`${process.env.REACT_APP_BACKEND}/licenses`, {
        ExpDate,
        MaxAgents,
        MaxUsers,
        Subscription,
        LicenseType
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log('License created:', response.data);
      setMessage('License created successfully!');
    } catch (error) {
      console.error('Error creating license:', error);
      setMessage('Error creating license');
    }
  };

  return (
    <div>
      <h2 className="form-heading">Create License</h2>
      <div className="license-form-container">
        <form onSubmit={handleSubmit}>
          <div>
            <label>Expiration Date:</label>
            <input type="date" value={ExpDate} onChange={(e) => setExpDate(e.target.value)} required />
          </div>
          <div>
            <label>Max Agents:</label>
            <input type="number" value={MaxAgents} onChange={(e) => setMaxAgents(e.target.value)} required />
          </div>
          <div>
            <label>MaxUsers:</label>
            <input type="number" value={MaxUsers} onChange={(e) => setMaxUsers(e.target.value)} required />
          </div>
          <div>
            <label>Renewal on Expiration Date:</label>
            <select value={Subscription} onChange={(e) => setSubscription(e.target.value)}>
              <option value={true}>True</option>
              <option value={false}>False</option>
            </select>
          </div>
          <div>
            <label>License Type:</label>
            <select value={LicenseType} onChange={(e) => setLicenseType(e.target.value)}>
              <option value='Free'>Free</option>
              <option value='Basic'>Basic</option>
              <option value='Enterprise'>Enterprise</option>
            </select>
          </div>
          <button type="submit">Create License</button>
          {message && <p>{message}</p>}
        </form>
      </div>
    </div>
  );
};

export default LicenseForm;
