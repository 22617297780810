import React, { useEffect, useState } from 'react';
import axios from 'axios';

const LogViewer = () => {
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchLogs = async () => {
            setLoading(true);
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${process.env.REACT_APP_BACKEND}/superUser/logs`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                const data = await response.data;
                setLogs(data);
            } catch (error) {
                console.error("Error fetching logs:", error);
            }
            setLoading(false);
        };

        fetchLogs();
    }, []);

    return (
        <div className="log-viewer">
            <h1>Log Viewer</h1>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <div className="log-container">
                    {logs.map((log, index) => (
                        <pre key={index}>{log.message}</pre>
                    ))}
                </div>
            )}
        </div>
    );
};

export default LogViewer;
