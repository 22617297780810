import React from 'react';
import { Link } from 'react-router-dom';

const NavigationBar = ({ userRole, onLogout }) => {
  return (
    <nav className="side-nav">
      <h1>Admin Panel</h1>
      <Link to="/">Home</Link>
      <Link to="/create">Create License</Link>
      {userRole === 'admin' && <Link to="/users">Latencetech's Users</Link>}
      <Link to="/report">Report</Link>
      <Link to="/vm-status">VMs Status</Link>
      <Link to="/my-account">My Account</Link>
      <Link to="log-view">View Logs</Link>
      <button onClick={onLogout} className="logout-btn">Logout</button>
    </nav>
  );
};

export default NavigationBar;