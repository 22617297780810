import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './VMStatus.css';

const VMStatus = () => {
  const [vmStatuses, setVMStatuses] = useState([]);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchVMStatuses = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SNMP_URL}/api/vm-statuses`);
        setVMStatuses(response.data);
        setMessage('');
      } catch (error) {
        console.error('Error fetching VM statuses:', error);
        setMessage('Error fetching VM statuses');
      }
    };

    fetchVMStatuses();
    const interval = setInterval(fetchVMStatuses, 60000);
    return () => clearInterval(interval);
  }, []);

  const formatUptime = (seconds) => {
    if (seconds === 'N/A') return 'N/A';
    const days = Math.floor(seconds / (3600 * 24));
    const hours = Math.floor(seconds % (3600 * 24) / 3600);
    const minutes = Math.floor(seconds % 3600 / 60);
    return `${days}d ${hours}h ${minutes}m`;
  };

  const formatUsage = (usage) => {
    return usage !== 'N/A' ? `${usage}%` : 'N/A';
  };

  return (
    <div className="vm-status">
      <div className="header">
        <h2>Virtual Machines Status</h2>
      </div>
      {message && <p className="message">{message}</p>}
      <table className="vm-status-table">
        <thead>
          <tr>
            <th>Component</th>
            <th>CPU Usage</th>
            <th>RAM Usage</th>
            <th>Storage Usage</th>
            <th>Uptime</th>
          </tr>
        </thead>
        <tbody>
          {vmStatuses && vmStatuses.map((vm, index) => (
            <tr key={index} className={vm.status}>
              <td>{vm.component}</td>
              <td>{formatUsage(vm.cpuUsage)}</td>
              <td>{formatUsage(vm.ramUsage)}</td>
              <td>{formatUsage(vm.storageUsage)}</td>
              <td>{formatUptime(vm.uptime)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default VMStatus;